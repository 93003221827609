import React from 'react'
import './home-page.css'

const HomePage = (): JSX.Element => {
  return (
    <main className='home-page-container'>
      <div className="content">
        <h1>Em breve teremos novidades!</h1>
        <p className='message-home-page'>Fique ligado em nossas redes sociais para acompanhar todas as atualizações e novidades.</p>
      </div>
    </main>
  )
}
export default HomePage