import React from 'react'
import { FaInstagram, FaLinkedin, FaWhatsapp, FaEnvelope } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import './footer.css'

const Footer = (): JSX.Element => {
  return (
    <footer className='footer'>
      <div className='social-icons'>
        <a href='https://wa.me/5511978444334?text=Olá,%20estou%20entrando%20em%20contato%20pelo%20site' target='_blank' rel='noopener noreferrer'>
          <FaWhatsapp />
        </a>
        <a href='http://www.instagram.com/smartgaas' target='_blank' rel='noopener noreferrer'>
          <FaInstagram />
        </a>
        <a href='https://br.linkedin.com/company/smartgaas' target='_blank' rel='noopener noreferrer'>
          <FaLinkedin />
        </a>
        <a href='https://x.com/smartgaas' target='_blank' rel='noopener noreferrer'>
          <FaXTwitter />
        </a>
        <a href='mailto:sac@smartgaas.com' target='_blank' rel='noopener noreferrer'>
          <FaEnvelope />
        </a>
      </div>
      <div className='company-address'>
        <p>
          A empresa SMART GAAS DO BRASIL INDUSTRIAS DE MAQUINAS E SERVICOS LTDA CNPJ: 29.209.654/0001-01
          está localizada na Alameda Madeira, 53, Andar 2 Sala 25 Parte B -
          Alphaville Centro Industrial e Empresarial/alphaville., Barueri - SP, 06.454-010.
        </p>
      </div>
    </footer>
  )
}
export default Footer