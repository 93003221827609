import React, { useState } from 'react'
import './header-menu.css'
import { Link } from 'react-router-dom'
import { FaBars, FaTimes } from 'react-icons/fa'
import img from '../../assets/i-fraud-only-text.png'

const HeaderMenu = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = (): void => setIsOpen(!isOpen)

  return (
    <header className='header-menu'>
      <div className='header-container'>
        <div>
          <Link to='https://homolportal.i-fraud.com/' title='I-fraud' >
            <img src={img} alt='Logo' className='header-image' />
          </Link>
        </div>
        <div>
          <nav className={`nav-menu ${isOpen ? 'open' : ''}`}>
            <ul>
              <li><Link to='/'>Home</Link></li>
              <li><Link to='/'>Empresa</Link></li>
              <li><Link to='/'>Serviços</Link></li>
              <li><Link to='/contato'>Contato</Link></li>
            </ul>
          </nav>
          <div className='hamburger' onClick={toggleMenu}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </div>
        </div>
      </div>
    </header>
  )
}

export default HeaderMenu