/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import './contato.css'

const Contato = (): JSX.Element => {
  return (
    <main className='contato-container'>
      <h1 className='contato-title'>Contato</h1>
      <p className='contato-subtitle'>
        Entre em contato conosco ou visite-nos no endereço abaixo:
      </p>
      <div className='contato-content'>
        <div className='map-responsive'>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3659.021139531214!2d-46.85199292475451!3d-23.49574805918472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf023a40874977%3A0xeb6b1f9821dcf6b5!2sCondom%C3%ADnio%20Edif%C3%ADcio%20Station%20Square%20-%20Alameda%20Madeira%2C%2053%20-%20Alphaville%2C%20Barueri%20-%20SP%2C%2006454-010!5e0!3m2!1spt-BR!2sbr!4v1725107246159!5m2!1spt-BR!2sbr'
            width='800'
            height='450'
            loading='lazy'
          >
          </iframe>
        </div>
      </div>
    </main>
  )
}
export default Contato